<template>
  <page-header-wrapper>
    <a-card>
      <div class="table-page-search-wrapper">
        <a-form layout="inline" class="table-head-ground">
          <div class="table-layer">
            <a-form-item label="" class="table-head-layout" style="max-width:350px;min-width:300px">
              <a-range-picker
                @change="onChangeRecord"
                :placeholder="['入账开始日期', '入账结束日期']"
                :value="recordDate"
                :showTime="{
                  hideDisabledOptions: true,
                  defaultValue: ['DD-MM-YY', 'DD-MM-YY'],}"
                format="YYYY-MM-DD"
                :disabled-date="disabledDate">
                <a-icon slot="suffixIcon" type="sync" />
              </a-range-picker>
            </a-form-item>
            <a-form-item label="" class="table-head-layout" style="max-width:350px;min-width:300px">
              <a-range-picker
                @change="onChange"
                :value="createdDate"
                :placeholder="['交易开始时间', '交易结束时间']"
                :showTime="{
                  hideDisabledOptions: true,
                  defaultValue: [moment('00:00:00', 'HH:mm:ss'), moment('23:59:59', 'HH:mm:ss')],
                }"
                format="YYYY-MM-DD HH:mm:ss"
                :disabled-date="disabledDate">
                <a-icon slot="suffixIcon" type="sync" />
              </a-range-picker>
            </a-form-item>
            <a-form-item label="" class="table-head-layout">
              <a-input v-model="searchData.orderNo" placeholder="请输入商户订单号" />
            </a-form-item>
            <a-form-item label="" class="table-head-layout" :wrapper-col="{ span: 16 }">
              <a-select v-model="searchData.tradeStatus" placeholder="交易状态" :allowClear="true">
                <a-select-option
                  v-for="(item) in StatusList"
                  :key="item.value"
                  :value="item.value"
                  :label="item.label">{{ item.label }}</a-select-option>
              </a-select>
            </a-form-item>
            <!-- payMode -->
            <a-form-item label="" class="table-head-layout" :wrapper-col="{ span: 16 }">
              <a-select v-model="searchData.payMode" placeholder="支付模式" :allowClear="true">
                <a-select-option
                  v-for="(item) in payModeList"
                  :key="item.value"
                  :value="item.value"
                  :label="item.label">{{ item.label }}</a-select-option>
              </a-select>
            </a-form-item>
            <span class="table-page-search-submitButtons">
              <a-button
                type="primary"
                icon="search"
                @click="queryFunc"
                :loading="btnLoading">搜索</a-button>
              <a-button style="margin-left: 8px" icon="reload" @click="reset">重置</a-button>
              <a-button style="margin-left: 8px" type="primary" icon="search" @click="exportfunc">导出</a-button>

            </span>

          </div>
        </a-form>
        <div style="display: flex;margin-bottom: 10px;align-items: center;justify-content: space-between;">
          <a-button type="primary" @click="showStatistics = !showStatistics">
            <a-icon type="bar-chart" /> {{ showStatistics ? '关闭统计' : '显示统计' }}
          </a-button>
        </div>
        <!--统计信息-->
        <div style="margin-bottom:20px">
          <div class="statistics-view" v-if="showStatistics" :searchData="searchData">
            <div style="flex: 1;justify-content: center;align-items: center;">
              <div class="statistics-item-view">
                <span>交易金额</span>
                <span class="amount-view">{{ orderInfo.amount | curreny }}<span class="text-view">元</span></span>
              </div>
            </div>
            <a-divider type="vertical" style="height: 90px; background-color: #e8e8e8" />
            <div style="flex: 1;justify-content: center;align-items: center;">
              <div class="statistics-item-view">
                <span>消费笔数</span>
                <span class="amount-black-view">{{ orderInfo.chargeCount }}<span
                  class="text-view">笔</span></span>
              </div>
            </div>
            <a-divider type="vertical" style="height: 90px; background-color: #e8e8e8" />
            <div style="flex: 1;justify-content: center;align-items: center;">
              <div class="statistics-item-view">
                <span>手续费金额</span>
                <span class="amount-view">{{ orderInfo.feeAmount | curreny }}<span class="text-view">元</span></span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 列表渲染 -->
      <JeepayTable
        @btnLoadClose="btnLoading = false"
        ref="infoTable"
        :initData="true"
        :closable="true"
        :searchData="searchData"
        :reqTableDataFunc="reqTableDataFunc"
        :tableColumns="tableColumns"
        rowKey="refundOrderId"
        :scrollX="1300">
        <template slot="tradeStatusSlot" slot-scope="{record}">
          {{ dict.statsList[record.tradeStatus] }}
        </template>
        <template slot="entryDateSlot" slot-scope="{record}">
          {{ record | dateFormat }}
        </template>
      </JeepayTable>
    </a-card>
  </page-header-wrapper>
</template>
<script>
import JeepayTextUp from '@/components/JeepayTextUp/JeepayTextUp' // 文字上移组件
import JeepayTable from '@/components/JeepayTable/JeepayTable'
import JeepayTableColumns from '@/components/JeepayTable/JeepayTableColumns'
import { req, API_URL_RECONCILIATION_CHARGElIST, API_URL_RECONCILIATION_CHARGECOUNT } from '@/api/manage'
import moment from 'moment'
import dict from './dict'
import request from '@/http/request'
import update from './update'
import { getNowStartDate, getNowEndDate } from '@/utils/util'
const tableColumns = [
    { key: 'entryDate', dataIndex: 'entryDate', title: '入账时间', width: '180px', scopedSlots: { customRender: 'entryDateSlot' } },
    { key: 'tradeTime', title: '交易时间', dataIndex: 'tradeTime', width: '180px' },
    { key: 'appId', title: 'appId', dataIndex: 'appId', width: '260px', ellipsis: true },
    { key: 'payId', title: '支付ID', dataIndex: 'payId', width: '260px', ellipsis: true },
    { key: 'orderNo', title: '商户订单号', dataIndex: 'orderNo', width: '260px' },
    { key: 'tradeType', title: '交易类型', dataIndex: 'tradeType', width: '160px' },
    { key: 'channelPayOrderNo', title: '渠道订单号', dataIndex: 'channelPayOrderNo', width: '260px' },
    { key: 'amount', title: '交易金额', dataIndex: 'amount', width: '180px', customRender: (amount) => { return amount ? parseFloat(amount).toFixed(2) : '0.00' } },
    { key: 'feeAmount', title: '手续费金额', dataIndex: 'feeAmount', width: '180px', customRender: (amount) => { return amount ? parseFloat(amount).toFixed(2) : '0.00' } },
    { key: 'currencyType', dataIndex: 'currencyType', title: '货币种类', width: '180px' },
    { key: 'tradeStatus', scopedSlots: { customRender: 'tradeStatusSlot' }, title: '交易状态', width: '180px' },
    { key: 'outTradeNo', dataIndex: 'outTradeNo', title: '第三方订单号', width: '260px', ellipsis: true },
    { key: 'payTime', dataIndex: 'payTime', title: '支付完成时间', width: '180px' },
    { key: 'payMode', dataIndex: 'payMode', title: '支付模式', width: '180px' },
    { key: 'goodsDesc', dataIndex: 'goodsDesc', title: '商品描述', width: '260px', ellipsis: true },
    { key: 'huaBei', dataIndex: 'huaBei', title: '花呗分期期数', width: '180px' }
    ]
export default {
    name: 'ReconciliationAdapayCharge',
    mixins: [update],
    components: {
        JeepayTextUp,
        JeepayTable,
        JeepayTableColumns
    },
    filters: {
        curreny: function (data) {
            data = data ? parseFloat(data).toFixed(2) : '0.00'
            return data
        },
        dateFormat: function (data) {
            return moment(data).format('YYYY-MM-DD')
        }
    },
    data () {
        return {
            btnLoading: false,
            tableColumns: tableColumns,
            searchData: {
                createdStart: '', // 选择开始时间
                createdEnd: '', // 选择结束时间
                entryDateStart: '',
                entryDateEnd: '',
                orderNo: '',
                tradeStatus: 'S',
                freezeStatus: undefined,
                payMode: undefined
            },
            createdDate: [getNowStartDate(), getNowEndDate()],
            payModeList: [
                {
                    value: '实时',
                    label: '实时'
                },
                {
                    value: '延时',
                    label: '延时'
                }
            ],
            dict: dict,
            showStatistics: false,
            orderInfo: {},
            recordDate: [getNowStartDate(), getNowEndDate()]
        }
    },
    mounted () {
        this.reqOrderCount()
    },
    methods: {
        // 请求统计接口数据
        reqOrderCount () {
            req.list(API_URL_RECONCILIATION_CHARGECOUNT, this.searchData).then(res => {
                this.orderInfo = res
            })
        },

        queryFunc () {
            this.btnLoading = true
            this.reqOrderCount()
            this.$refs.infoTable.refTable(true)
        },
        // 请求table接口数据
        reqTableDataFunc: (params) => {
            return req.list(API_URL_RECONCILIATION_CHARGElIST, params)
        },
        searchFunc () { // 点击【查询】按钮点击事件
            this.$refs.infoTable.refTable(true)
        },
        moment,
        onChange (date, dateString) {
            this.createdDate = dateString
            this.searchData.createdStart = dateString[0] // 开始时间
            this.searchData.createdEnd = dateString[1] // 结束时间
        },
        onChangeRecord (date, dateString) {
            this.recordDate = dateString
            this.searchData.entryDateStart = dateString[0] && dateString[0].replace(/-/g, '') // 开始时间
            this.searchData.entryDateEnd = dateString[1] && dateString[1].replace(/-/g, '') // 结束时间
        },
        disabledDate (current) { // 今日之后日期不可选
            return current && current > moment().endOf('day')
        },
        reset () {
            this.searchData = {
                createdStart: '', // 选择开始时间
                createdEnd: '', // 选择结束时间
                entryDateStart: '',
                entryDateEnd: '',
                orderNo: '',
                tradeStatus: 'S',
                freezeStatus: undefined,
                payMode: undefined
            }
            this.createdDate = [getNowStartDate(), getNowEndDate()]
            this.recordDate = [getNowStartDate(), getNowEndDate()]
            setTimeout(() => { this.$refs.infoTable.refTable(true) }, 1000)
        },
        exportfunc () {
            request.request({
                url: '/mch/reconciliation/adapay/data/asynExportChargeList',
                method: 'post',
                data: this.searchData
            }).then(res => {
                this.$message.success(res)
            })
        }
    }
}
</script>
<style scoped lang="less">
.statistics-view {
    background-color: #fff;
    border: 1px solid #e8e8e8;
    border-radius: 3px;
    flex-direction: row;
    display: flex;
    margin-top: 20px;
    padding: 30px 0px 30px 0px;

}

.statistics-item-view {
    flex-direction: column;
    display: flex;
    text-align: start;
    align-items: center;
}

.amount-view {
    font-size: 20px;
    color: #5485f8;
    font-weight: bold;
    margin-top: 10px;
}

.amount-black-view {
    font-size: 20px;
    color: #333;
    font-weight: bold;
    margin-top: 10px;
}

.amount-h-view {
    font-size: 20px;
    color: #dbaf0e;
    font-weight: bold;
    margin-top: 10px;
}

.text-view {
    font-size: 14px;
    font-weight: normal;
}

.mx-text {
    font-size: 14px;
    font-weight: normal;
    color: #55a2ff;
    padding-left: 10px;
}

::v-deep .ant-upload-list {
    width: 300px !important;
}
</style>
